/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import nls_de from "./i18n/de/nls.json";
import nls_en from "./i18n/en/nls.json";
import nls_es from "./i18n/es/nls.json";
import nls_fr from "./i18n/fr/nls.json";
import nls_it from "./i18n/it/nls.json";
import nls_bg from "./i18n/bg/nls.json";
import nls_cs from "./i18n/cs/nls.json";
import nls_hr from "./i18n/hr/nls.json";
import nls_hu from "./i18n/hu/nls.json";
import nls_sl from "./i18n/sl/nls.json";
import nls_sk from "./i18n/sk/nls.json";
import nls_sv from "./i18n/sv/nls.json";

i18n
    .use(initReactI18next)
    .init({
        lng: 'en',
        resources: {
            en: {
                nls: nls_en
            },
            de: {
                nls: nls_de
            },
            es: {
                nls: nls_es
            },
            fr: {
                nls: nls_fr
            },
            it: {
                nls: nls_it
            },
            bg: {
                nls: nls_bg
            },
            cs: {
                nls: nls_cs
            },
            hr: {
                nls: nls_hr
            },
            hu: {
                nls: nls_hu
            },
            sv: {
                nls: nls_sv
            },
            sl: {
                nls: nls_sl
            },
            sk: {
                nls: nls_sk
            }
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });
