/**********************************************************************
 *
 * Copyright (C) 2022 Almendo Technologies GmbH
 * Copyright (C) 2022 Marcus Harringer
 *
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of
 * Marcus Harringer. The intellectual and technical concepts contained
 * herein are proprietary to Marcus Harringer and may be covered
 * copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Marcus Harringer.
 *
 ***********************************************************************/
import {useRef, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography
} from '@mui/material';

import useAuth from '../../hooks/useAuth';

//DATE
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/en-gb'; //default last
import {logError} from '../log/Logger';

//NLS
const languageOptions = {
    en: {
        icon: '/static/icons/flags/us.svg',
        label: 'English'
    },
    de: {
        icon: '/static/icons/flags/de-at.svg',
        label: 'Deutsch'
    },
    es: {
        icon: '/static/icons/flags/es.svg',
        label: 'Español'
    },
    fr: {
        icon: '/static/icons/flags/fr.svg',
        label: 'Français'
    },
    it: {
        icon: '/static/icons/flags/it.svg',
        label: 'Italiano'
    },
    bg: {
        icon: '/static/icons/flags/bg.svg',
        label: 'Български'
    },
    cs: {
        icon: '/static/icons/flags/cz.svg',
        label: 'Čeština'
    },
    hr: {
        icon: '/static/icons/flags/hr.svg',
        label: 'Hrvatski'
    },
    hu: {
        icon: '/static/icons/flags/hu.svg',
        label: 'Magyar'
    },
    sv: {
        icon: '/static/icons/flags/se.svg',
        label: 'Svenska'
    },
    sl: {
        icon: '/static/icons/flags/si.svg',
        label: 'Slovenščina'
    },
    sk: {
        icon: '/static/icons/flags/sk.svg',
        label: 'Slovenčina'
    }
};

/**********************************************************************
 * PAGE
 ***********************************************************************/
const LanguagePopover = () => {
    const anchorRef = useRef(null);
    const {i18n} = useTranslation();
    const [open, setOpen] = useState(false);
    const {user} = useAuth();

    const changeLanguage = (language) => {

        i18n.changeLanguage(language);

        if (user != null) {

            try {

                if (user.language === 'en') {
                    moment.locale('en-gb');
                } else {
                    moment.locale(user.language);
                }

            } catch (err) {
                logError("LangugePopover", err);
            }
        }
    }


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeLanguage = (language) => {
        changeLanguage(language);
        setOpen(false);
    };


    useEffect(() => {
        if (user != null) {
            changeLanguage(user.language);
        }
    }, [user]);


    const selectedOption = languageOptions[i18n.language];

    return <>
        <IconButton onClick={handleOpen} ref={anchorRef} size="large">
            <Box
                sx={{
                    display: 'flex',
                    height: 20,
                    width: 20,
                    '& img': {
                        width: '100%'
                    }
                }}
            >
                <img
                    alt={selectedOption.label}
                    src={selectedOption.icon}
                />
            </Box>
        </IconButton>
        <Popover
            anchorEl={anchorRef.current}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
            }}
            keepMounted
            onClose={handleClose}
            open={open}
            PaperProps={{
                sx: {width: 240}
            }}
        >
            {Object.keys(languageOptions).map((language) => (
                <MenuItem
                    onClick={() => handleChangeLanguage(language)}
                    key={language}
                >
                    <ListItemIcon>
                        <Box
                            sx={{
                                display: 'flex',
                                height: 20,
                                width: 20,
                                '& img': {
                                    width: '100%'
                                }
                            }}
                        >
                            <img
                                alt={languageOptions[language].label}
                                src={languageOptions[language].icon}
                            />
                        </Box>
                    </ListItemIcon>
                    <ListItemText
                        primary={(
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {languageOptions[language].label}
                            </Typography>
                        )}
                    />
                </MenuItem>
            ))}
        </Popover>
    </>;
};

export default LanguagePopover;
