/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($user_id: ID!) {
    getUser(user_id: $user_id) {
      user_id
      user_type
      user_status
      user_role
      photo
      email
      name
      phone
      country
      city
      zip
      street
      language
      customer
      usage_plan
      usage_expiry
      usage_contract
      usage_price
      usage_period
      billing_name
      billing_company
      billing_country
      billing_zip
      billing_city
      billing_street
      billing_vat
      billing_currency
      alarm_channel
      alarm_hysteresis {
        minutes
        __typename
      }
      alarm_sensor
      alarm_connection
      alarm_battery
      alarm_hardware
      alarm_signal
      alarm_power
      alarm_firmware
      api_key
      created
      updated
      __typename
    }
  }
`;
export const getUserPublic = /* GraphQL */ `
  query GetUserPublic($user_id: ID!) {
    getUserPublic(user_id: $user_id) {
      user_id
      email
      name
      photo
      __typename
    }
  }
`;
export const getUserShare = /* GraphQL */ `
  query GetUserShare($user_id: ID!, $share_id: ID!) {
    getUserShare(user_id: $user_id, share_id: $share_id) {
      user_id
      share_id
      resource
      type
      authorized
      role
      status
      description
      message
      email
      created
      updated
      __typename
    }
  }
`;
export const getUserShares = /* GraphQL */ `
  query GetUserShares($user_id: ID!) {
    getUserShares(user_id: $user_id) {
      user_id
      share_id
      resource
      type
      authorized
      role
      status
      description
      message
      email
      created
      updated
      __typename
    }
  }
`;
export const getUserSharesAuthorized = /* GraphQL */ `
  query GetUserSharesAuthorized($user_id: ID!) {
    getUserSharesAuthorized(user_id: $user_id) {
      user_id
      share_id
      resource
      type
      authorized
      role
      status
      description
      message
      email
      created
      updated
      __typename
    }
  }
`;
export const getUserShareByResource = /* GraphQL */ `
  query GetUserShareByResource($user_id: ID!, $resource: ID!, $type: Int!) {
    getUserShareByResource(
      user_id: $user_id
      resource: $resource
      type: $type
    ) {
      user_id
      share_id
      resource
      type
      authorized
      role
      status
      description
      message
      email
      created
      updated
      __typename
    }
  }
`;
export const getUserAlarm = /* GraphQL */ `
  query GetUserAlarm($user_id: ID!, $ts_iso: String!) {
    getUserAlarm(user_id: $user_id, ts_iso: $ts_iso) {
      user_id
      ts_iso
      ts_unix
      serialnr
      alarm_type
      alarm_severity
      alarm_sensor {
        tem_high {
          active
          timestamp
          state
          __typename
        }
        tem_low {
          active
          timestamp
          state
          __typename
        }
        hum_high {
          active
          timestamp
          state
          __typename
        }
        hum_low {
          active
          timestamp
          state
          __typename
        }
        co2_limit {
          active
          timestamp
          state
          __typename
        }
        tvoc_limit {
          active
          timestamp
          state
          __typename
        }
        aiq_limit {
          active
          timestamp
          state
          __typename
        }
        voc_limit {
          active
          timestamp
          state
          __typename
        }
        pm1_limit {
          active
          timestamp
          state
          __typename
        }
        pm2_limit {
          active
          timestamp
          state
          __typename
        }
        pm4_limit {
          active
          timestamp
          state
          __typename
        }
        pm10_limit {
          active
          timestamp
          state
          __typename
        }
        light_low {
          active
          timestamp
          state
          __typename
        }
        light_high {
          active
          timestamp
          state
          __typename
        }
        light_white_low {
          active
          timestamp
          state
          __typename
        }
        light_white_high {
          active
          timestamp
          state
          __typename
        }
        uix_low {
          active
          timestamp
          state
          __typename
        }
        uix_high {
          active
          timestamp
          state
          __typename
        }
        rpm_low {
          active
          timestamp
          state
          __typename
        }
        rpm_high {
          active
          timestamp
          state
          __typename
        }
        tem1_low {
          active
          timestamp
          state
          __typename
        }
        tem1_high {
          active
          timestamp
          state
          __typename
        }
        gw_things_low {
          active
          timestamp
          state
          __typename
        }
        gw_things_high {
          active
          timestamp
          state
          __typename
        }
        gw_sensors_low {
          active
          timestamp
          state
          __typename
        }
        gw_sensors_high {
          active
          timestamp
          state
          __typename
        }
        gw_beacons_low {
          active
          timestamp
          state
          __typename
        }
        gw_beacons_high {
          active
          timestamp
          state
          __typename
        }
        cap1_rp_low {
          active
          timestamp
          state
          __typename
        }
        cap1_rp_high {
          active
          timestamp
          state
          __typename
        }
        cap2_rp_low {
          active
          timestamp
          state
          __typename
        }
        cap2_rp_high {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_sensor_state
      alarm_connection {
        disconnected {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_connection_state
      alarm_battery {
        battery_low {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_battery_state
      alarm_signal {
        signal_low {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_signal_state
      alarm_hardware {
        sensor {
          active
          timestamp
          state
          __typename
        }
        memory {
          active
          timestamp
          state
          __typename
        }
        storage {
          active
          timestamp
          state
          __typename
        }
        clock {
          active
          timestamp
          state
          __typename
        }
        selftest {
          active
          timestamp
          state
          __typename
        }
        environment {
          active
          timestamp
          state
          __typename
        }
        overheat {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_hardware_state
      alarm_firmware {
        update_failed {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_firmware_state
      alarm_power {
        powerloss {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_power_state
      channel
      email
      thing_type
      thing {
        monitoring
        __typename
      }
      asset_id
      asset {
        name
        __typename
      }
      facility_id
      facility {
        name
        __typename
      }
      shadow {
        desired {
          config {
            name
            location
            gps
            alarm_mode
            tem_high
            tem_low
            hum_high
            hum_low
            co2_limit
            tvoc_limit
            aiq_limit
            voc_limit
            pressure_high
            pressure_low
            pm1_limit
            pm2_limit
            pm4_limit
            pm10_limit
            light_low
            light_high
            light_white_low
            light_white_high
            uix_low
            uix_high
            relay1
            relay2
            rpm_low
            rpm_high
            tem1_low
            tem1_high
            tem2_low
            tem2_high
            gw_things_low
            gw_things_high
            gw_sensors_low
            gw_sensors_high
            gw_beacons_low
            gw_beacons_high
            voltage_high
            voltage_low
            battery_low
            battery_high
            cap1_rp_low
            cap1_rp_high
            cap2_rp_low
            cap2_rp_high
            __typename
          }
          config_pro {
            interval_sec
            tem_offset
            __typename
          }
          __typename
        }
        reported {
          serialnr
          type
          online
          ts_unix
          ts_iso
          gps
          limits
          model
          boot
          battery
          firmware
          config {
            name
            location
            gps
            alarm_mode
            tem_high
            tem_low
            hum_high
            hum_low
            co2_limit
            tvoc_limit
            aiq_limit
            voc_limit
            pressure_high
            pressure_low
            pm1_limit
            pm2_limit
            pm4_limit
            pm10_limit
            light_low
            light_high
            light_white_low
            light_white_high
            uix_low
            uix_high
            relay1
            relay2
            rpm_low
            rpm_high
            tem1_low
            tem1_high
            tem2_low
            tem2_high
            gw_things_low
            gw_things_high
            gw_sensors_low
            gw_sensors_high
            gw_beacons_low
            gw_beacons_high
            voltage_high
            voltage_low
            battery_low
            battery_high
            cap1_rp_low
            cap1_rp_high
            cap2_rp_low
            cap2_rp_high
            __typename
          }
          config_pro {
            interval_sec
            tem_offset
            __typename
          }
          hum
          tem
          dew
          co2
          tvoc
          aiq
          voc
          pressure
          pm1
          pm2
          pm4
          pm10
          pm1_nc
          pm2_nc
          pm4_nc
          pm10_nc
          pm_typical
          resistance
          rpm
          tem1
          tem2
          voltage
          hours
          light
          light_white
          uix
          uix_sec
          relay1
          relay2
          gw_things
          gw_sensors
          gw_beacons
          beacon_uid
          beacon_namespace_id
          beacon_instance_id
          beacon_tx
          beacon_tlm
          beacon_time
          cnd1
          cnd2
          cnd3
          cnd4
          cap1_t
          cap1_r
          cap1_rp
          cap2_t
          cap2_r
          cap2_rp
          flow_t
          uid1
          uid2
          rssi
          wifi_rssi
          lte_rssi
          lpwan_rssi
          mno
          mno_iccid
          state_info
          state_job
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getUserAlarms = /* GraphQL */ `
  query GetUserAlarms($user_id: ID!, $ts_begin: String!, $ts_end: String!) {
    getUserAlarms(user_id: $user_id, ts_begin: $ts_begin, ts_end: $ts_end) {
      user_id
      ts_iso
      ts_unix
      serialnr
      alarm_type
      alarm_severity
      alarm_sensor {
        tem_high {
          active
          timestamp
          state
          __typename
        }
        tem_low {
          active
          timestamp
          state
          __typename
        }
        hum_high {
          active
          timestamp
          state
          __typename
        }
        hum_low {
          active
          timestamp
          state
          __typename
        }
        co2_limit {
          active
          timestamp
          state
          __typename
        }
        tvoc_limit {
          active
          timestamp
          state
          __typename
        }
        aiq_limit {
          active
          timestamp
          state
          __typename
        }
        voc_limit {
          active
          timestamp
          state
          __typename
        }
        pm1_limit {
          active
          timestamp
          state
          __typename
        }
        pm2_limit {
          active
          timestamp
          state
          __typename
        }
        pm4_limit {
          active
          timestamp
          state
          __typename
        }
        pm10_limit {
          active
          timestamp
          state
          __typename
        }
        light_low {
          active
          timestamp
          state
          __typename
        }
        light_high {
          active
          timestamp
          state
          __typename
        }
        light_white_low {
          active
          timestamp
          state
          __typename
        }
        light_white_high {
          active
          timestamp
          state
          __typename
        }
        uix_low {
          active
          timestamp
          state
          __typename
        }
        uix_high {
          active
          timestamp
          state
          __typename
        }
        rpm_low {
          active
          timestamp
          state
          __typename
        }
        rpm_high {
          active
          timestamp
          state
          __typename
        }
        tem1_low {
          active
          timestamp
          state
          __typename
        }
        tem1_high {
          active
          timestamp
          state
          __typename
        }
        gw_things_low {
          active
          timestamp
          state
          __typename
        }
        gw_things_high {
          active
          timestamp
          state
          __typename
        }
        gw_sensors_low {
          active
          timestamp
          state
          __typename
        }
        gw_sensors_high {
          active
          timestamp
          state
          __typename
        }
        gw_beacons_low {
          active
          timestamp
          state
          __typename
        }
        gw_beacons_high {
          active
          timestamp
          state
          __typename
        }
        cap1_rp_low {
          active
          timestamp
          state
          __typename
        }
        cap1_rp_high {
          active
          timestamp
          state
          __typename
        }
        cap2_rp_low {
          active
          timestamp
          state
          __typename
        }
        cap2_rp_high {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_sensor_state
      alarm_connection {
        disconnected {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_connection_state
      alarm_battery {
        battery_low {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_battery_state
      alarm_signal {
        signal_low {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_signal_state
      alarm_hardware {
        sensor {
          active
          timestamp
          state
          __typename
        }
        memory {
          active
          timestamp
          state
          __typename
        }
        storage {
          active
          timestamp
          state
          __typename
        }
        clock {
          active
          timestamp
          state
          __typename
        }
        selftest {
          active
          timestamp
          state
          __typename
        }
        environment {
          active
          timestamp
          state
          __typename
        }
        overheat {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_hardware_state
      alarm_firmware {
        update_failed {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_firmware_state
      alarm_power {
        powerloss {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_power_state
      channel
      email
      thing_type
      thing {
        monitoring
        __typename
      }
      asset_id
      asset {
        name
        __typename
      }
      facility_id
      facility {
        name
        __typename
      }
      shadow {
        desired {
          config {
            name
            location
            gps
            alarm_mode
            tem_high
            tem_low
            hum_high
            hum_low
            co2_limit
            tvoc_limit
            aiq_limit
            voc_limit
            pressure_high
            pressure_low
            pm1_limit
            pm2_limit
            pm4_limit
            pm10_limit
            light_low
            light_high
            light_white_low
            light_white_high
            uix_low
            uix_high
            relay1
            relay2
            rpm_low
            rpm_high
            tem1_low
            tem1_high
            tem2_low
            tem2_high
            gw_things_low
            gw_things_high
            gw_sensors_low
            gw_sensors_high
            gw_beacons_low
            gw_beacons_high
            voltage_high
            voltage_low
            battery_low
            battery_high
            cap1_rp_low
            cap1_rp_high
            cap2_rp_low
            cap2_rp_high
            __typename
          }
          config_pro {
            interval_sec
            tem_offset
            __typename
          }
          __typename
        }
        reported {
          serialnr
          type
          online
          ts_unix
          ts_iso
          gps
          limits
          model
          boot
          battery
          firmware
          config {
            name
            location
            gps
            alarm_mode
            tem_high
            tem_low
            hum_high
            hum_low
            co2_limit
            tvoc_limit
            aiq_limit
            voc_limit
            pressure_high
            pressure_low
            pm1_limit
            pm2_limit
            pm4_limit
            pm10_limit
            light_low
            light_high
            light_white_low
            light_white_high
            uix_low
            uix_high
            relay1
            relay2
            rpm_low
            rpm_high
            tem1_low
            tem1_high
            tem2_low
            tem2_high
            gw_things_low
            gw_things_high
            gw_sensors_low
            gw_sensors_high
            gw_beacons_low
            gw_beacons_high
            voltage_high
            voltage_low
            battery_low
            battery_high
            cap1_rp_low
            cap1_rp_high
            cap2_rp_low
            cap2_rp_high
            __typename
          }
          config_pro {
            interval_sec
            tem_offset
            __typename
          }
          hum
          tem
          dew
          co2
          tvoc
          aiq
          voc
          pressure
          pm1
          pm2
          pm4
          pm10
          pm1_nc
          pm2_nc
          pm4_nc
          pm10_nc
          pm_typical
          resistance
          rpm
          tem1
          tem2
          voltage
          hours
          light
          light_white
          uix
          uix_sec
          relay1
          relay2
          gw_things
          gw_sensors
          gw_beacons
          beacon_uid
          beacon_namespace_id
          beacon_instance_id
          beacon_tx
          beacon_tlm
          beacon_time
          cnd1
          cnd2
          cnd3
          cnd4
          cap1_t
          cap1_r
          cap1_rp
          cap2_t
          cap2_r
          cap2_rp
          flow_t
          uid1
          uid2
          rssi
          wifi_rssi
          lte_rssi
          lpwan_rssi
          mno
          mno_iccid
          state_info
          state_job
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getUserFacility = /* GraphQL */ `
  query GetUserFacility($user_id: ID!, $facility_id: ID!) {
    getUserFacility(user_id: $user_id, facility_id: $facility_id) {
      user_id
      facility_id
      city
      photo
      zip
      gps
      description
      notes
      category
      tags
      country
      name
      street
      created
      updated
      __typename
    }
  }
`;
export const getUserFacilities = /* GraphQL */ `
  query GetUserFacilities($user_id: ID!) {
    getUserFacilities(user_id: $user_id) {
      user_id
      facility_id
      city
      photo
      zip
      gps
      description
      notes
      category
      tags
      country
      name
      street
      created
      updated
      __typename
    }
  }
`;
export const getUserAsset = /* GraphQL */ `
  query GetUserAsset($user_id: ID!, $asset_id: ID!) {
    getUserAsset(user_id: $user_id, asset_id: $asset_id) {
      user_id
      asset_id
      facility_id
      name
      description
      notes
      category
      tags
      status
      photo
      manufacturer
      model
      modelnr
      serialnr
      gps
      created
      updated
      __typename
    }
  }
`;
export const getUserAssets = /* GraphQL */ `
  query GetUserAssets($user_id: ID!) {
    getUserAssets(user_id: $user_id) {
      user_id
      asset_id
      facility_id
      name
      description
      notes
      category
      tags
      status
      photo
      manufacturer
      model
      modelnr
      serialnr
      gps
      created
      updated
      __typename
    }
  }
`;
export const getUserAssetsByFacility = /* GraphQL */ `
  query GetUserAssetsByFacility($user_id: ID!, $facility_id: ID!) {
    getUserAssetsByFacility(user_id: $user_id, facility_id: $facility_id) {
      user_id
      asset_id
      facility_id
      name
      description
      notes
      category
      tags
      status
      photo
      manufacturer
      model
      modelnr
      serialnr
      gps
      created
      updated
      __typename
    }
  }
`;
export const getUserFiles = /* GraphQL */ `
  query GetUserFiles($user_id: ID!) {
    getUserFiles(user_id: $user_id) {
      user_id
      file_id
      asset_id
      facility_id
      serialnr
      s3
      name
      type
      updated
      modified
      size
      tags
      __typename
    }
  }
`;
export const getUserFilesByFacility = /* GraphQL */ `
  query GetUserFilesByFacility($user_id: ID!, $facility_id: ID!) {
    getUserFilesByFacility(user_id: $user_id, facility_id: $facility_id) {
      user_id
      file_id
      asset_id
      facility_id
      serialnr
      s3
      name
      type
      updated
      modified
      size
      tags
      __typename
    }
  }
`;
export const getUserFilesByAsset = /* GraphQL */ `
  query GetUserFilesByAsset($user_id: ID!, $asset_id: ID!) {
    getUserFilesByAsset(user_id: $user_id, asset_id: $asset_id) {
      user_id
      file_id
      asset_id
      facility_id
      serialnr
      s3
      name
      type
      updated
      modified
      size
      tags
      __typename
    }
  }
`;
export const getUserFilesByThing = /* GraphQL */ `
  query GetUserFilesByThing($user_id: ID!, $serialnr: ID!) {
    getUserFilesByThing(user_id: $user_id, serialnr: $serialnr) {
      user_id
      file_id
      asset_id
      facility_id
      serialnr
      s3
      name
      type
      updated
      modified
      size
      tags
      __typename
    }
  }
`;
export const getUserDocForm = /* GraphQL */ `
  query GetUserDocForm($user_id: ID!, $form_id: ID!) {
    getUserDocForm(user_id: $user_id, form_id: $form_id) {
      form_id
      user_id
      asset_id
      facility_id
      title
      description
      schema
      schema_ui
      created
      updated
      tags
      status
      __typename
    }
  }
`;
export const getUserDocForms = /* GraphQL */ `
  query GetUserDocForms($user_id: ID!) {
    getUserDocForms(user_id: $user_id) {
      form_id
      user_id
      asset_id
      facility_id
      title
      description
      schema
      schema_ui
      created
      updated
      tags
      status
      __typename
    }
  }
`;
export const getUserDocFormsByFacility = /* GraphQL */ `
  query GetUserDocFormsByFacility($user_id: ID!, $facility_id: ID!) {
    getUserDocFormsByFacility(user_id: $user_id, facility_id: $facility_id) {
      form_id
      user_id
      asset_id
      facility_id
      title
      description
      schema
      schema_ui
      created
      updated
      tags
      status
      __typename
    }
  }
`;
export const getUserDocBook = /* GraphQL */ `
  query GetUserDocBook($user_id: ID!, $book_id: ID!) {
    getUserDocBook(user_id: $user_id, book_id: $book_id) {
      book_id
      user_id
      asset_id
      facility_id
      serialnr
      title
      color
      labels
      created
      updated
      status
      __typename
    }
  }
`;
export const getUserDocBooks = /* GraphQL */ `
  query GetUserDocBooks($user_id: ID!) {
    getUserDocBooks(user_id: $user_id) {
      book_id
      user_id
      asset_id
      facility_id
      serialnr
      title
      color
      labels
      created
      updated
      status
      __typename
    }
  }
`;
export const getUserDocBooksByAsset = /* GraphQL */ `
  query GetUserDocBooksByAsset($user_id: ID!, $asset_id: ID!) {
    getUserDocBooksByAsset(user_id: $user_id, asset_id: $asset_id) {
      book_id
      user_id
      asset_id
      facility_id
      serialnr
      title
      color
      labels
      created
      updated
      status
      __typename
    }
  }
`;
export const getUserDoc = /* GraphQL */ `
  query GetUserDoc($user_id: ID!, $doc_id: ID!) {
    getUserDoc(user_id: $user_id, doc_id: $doc_id) {
      doc_id
      user_id
      book_id
      book_label
      title
      description
      status
      tags
      form_id
      data
      ts_iso
      ts_unix
      edit_roles
      approval
      modified_by
      __typename
    }
  }
`;
export const getUserDocsByBook = /* GraphQL */ `
  query GetUserDocsByBook($user_id: ID!, $book_id: ID!) {
    getUserDocsByBook(user_id: $user_id, book_id: $book_id) {
      doc_id
      user_id
      book_id
      book_label
      title
      description
      status
      tags
      form_id
      data
      ts_iso
      ts_unix
      edit_roles
      approval
      modified_by
      __typename
    }
  }
`;
export const getUserDocsByForm = /* GraphQL */ `
  query GetUserDocsByForm($user_id: ID!, $form_id: ID!) {
    getUserDocsByForm(user_id: $user_id, form_id: $form_id) {
      doc_id
      user_id
      book_id
      book_label
      title
      description
      status
      tags
      form_id
      data
      ts_iso
      ts_unix
      edit_roles
      approval
      modified_by
      __typename
    }
  }
`;
export const getUserDocsBySearch = /* GraphQL */ `
  query GetUserDocsBySearch($user_id: ID!, $search: String!) {
    getUserDocsBySearch(user_id: $user_id, search: $search) {
      doc_id
      user_id
      book_id
      book_label
      title
      description
      status
      tags
      form_id
      data
      ts_iso
      ts_unix
      edit_roles
      approval
      modified_by
      __typename
    }
  }
`;
export const getUserDocVersions = /* GraphQL */ `
  query GetUserDocVersions($user_id: ID!, $doc_id: ID!) {
    getUserDocVersions(user_id: $user_id, doc_id: $doc_id) {
      doc_id
      ts_iso
      ts_unix
      user_id
      book_id
      book_label
      title
      description
      status
      tags
      form_id
      data
      schema
      schema_ui
      edit_roles
      approval
      modified_by
      __typename
    }
  }
`;
export const getUserDocVersion = /* GraphQL */ `
  query GetUserDocVersion($user_id: ID!, $doc_id: ID!, $ts_iso: String!) {
    getUserDocVersion(user_id: $user_id, doc_id: $doc_id, ts_iso: $ts_iso) {
      doc_id
      ts_iso
      ts_unix
      user_id
      book_id
      book_label
      title
      description
      status
      tags
      form_id
      data
      schema
      schema_ui
      edit_roles
      approval
      modified_by
      __typename
    }
  }
`;
export const getUserDocVersionLatest = /* GraphQL */ `
  query GetUserDocVersionLatest($user_id: ID!, $doc_id: ID!) {
    getUserDocVersionLatest(user_id: $user_id, doc_id: $doc_id) {
      doc_id
      ts_iso
      ts_unix
      user_id
      book_id
      book_label
      title
      description
      status
      tags
      form_id
      data
      schema
      schema_ui
      edit_roles
      approval
      modified_by
      __typename
    }
  }
`;
export const getUserThing = /* GraphQL */ `
  query GetUserThing($user_id: ID!, $serialnr: ID!) {
    getUserThing(user_id: $user_id, serialnr: $serialnr) {
      user_id
      serialnr
      type
      asset_id
      gwid
      monitoring
      notes
      photo
      tags
      status
      created
      updated
      alarm_sensor {
        tem_high {
          active
          timestamp
          state
          __typename
        }
        tem_low {
          active
          timestamp
          state
          __typename
        }
        hum_high {
          active
          timestamp
          state
          __typename
        }
        hum_low {
          active
          timestamp
          state
          __typename
        }
        co2_limit {
          active
          timestamp
          state
          __typename
        }
        tvoc_limit {
          active
          timestamp
          state
          __typename
        }
        aiq_limit {
          active
          timestamp
          state
          __typename
        }
        voc_limit {
          active
          timestamp
          state
          __typename
        }
        pm1_limit {
          active
          timestamp
          state
          __typename
        }
        pm2_limit {
          active
          timestamp
          state
          __typename
        }
        pm4_limit {
          active
          timestamp
          state
          __typename
        }
        pm10_limit {
          active
          timestamp
          state
          __typename
        }
        light_low {
          active
          timestamp
          state
          __typename
        }
        light_high {
          active
          timestamp
          state
          __typename
        }
        light_white_low {
          active
          timestamp
          state
          __typename
        }
        light_white_high {
          active
          timestamp
          state
          __typename
        }
        uix_low {
          active
          timestamp
          state
          __typename
        }
        uix_high {
          active
          timestamp
          state
          __typename
        }
        rpm_low {
          active
          timestamp
          state
          __typename
        }
        rpm_high {
          active
          timestamp
          state
          __typename
        }
        tem1_low {
          active
          timestamp
          state
          __typename
        }
        tem1_high {
          active
          timestamp
          state
          __typename
        }
        gw_things_low {
          active
          timestamp
          state
          __typename
        }
        gw_things_high {
          active
          timestamp
          state
          __typename
        }
        gw_sensors_low {
          active
          timestamp
          state
          __typename
        }
        gw_sensors_high {
          active
          timestamp
          state
          __typename
        }
        gw_beacons_low {
          active
          timestamp
          state
          __typename
        }
        gw_beacons_high {
          active
          timestamp
          state
          __typename
        }
        cap1_rp_low {
          active
          timestamp
          state
          __typename
        }
        cap1_rp_high {
          active
          timestamp
          state
          __typename
        }
        cap2_rp_low {
          active
          timestamp
          state
          __typename
        }
        cap2_rp_high {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_sensor_state
      alarm_connection {
        disconnected {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_connection_state
      alarm_battery {
        battery_low {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_battery_state
      alarm_signal {
        signal_low {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_signal_state
      alarm_hardware {
        sensor {
          active
          timestamp
          state
          __typename
        }
        memory {
          active
          timestamp
          state
          __typename
        }
        storage {
          active
          timestamp
          state
          __typename
        }
        clock {
          active
          timestamp
          state
          __typename
        }
        selftest {
          active
          timestamp
          state
          __typename
        }
        environment {
          active
          timestamp
          state
          __typename
        }
        overheat {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_hardware_state
      alarm_firmware {
        update_failed {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_firmware_state
      alarm_power {
        powerloss {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_power_state
      online
      ts_unix
      ts_iso
      shadow {
        desired {
          config {
            name
            location
            gps
            alarm_mode
            tem_high
            tem_low
            hum_high
            hum_low
            co2_limit
            tvoc_limit
            aiq_limit
            voc_limit
            pressure_high
            pressure_low
            pm1_limit
            pm2_limit
            pm4_limit
            pm10_limit
            light_low
            light_high
            light_white_low
            light_white_high
            uix_low
            uix_high
            relay1
            relay2
            rpm_low
            rpm_high
            tem1_low
            tem1_high
            tem2_low
            tem2_high
            gw_things_low
            gw_things_high
            gw_sensors_low
            gw_sensors_high
            gw_beacons_low
            gw_beacons_high
            voltage_high
            voltage_low
            battery_low
            battery_high
            cap1_rp_low
            cap1_rp_high
            cap2_rp_low
            cap2_rp_high
            __typename
          }
          config_pro {
            interval_sec
            tem_offset
            __typename
          }
          __typename
        }
        reported {
          serialnr
          type
          online
          ts_unix
          ts_iso
          gps
          limits
          model
          boot
          battery
          firmware
          config {
            name
            location
            gps
            alarm_mode
            tem_high
            tem_low
            hum_high
            hum_low
            co2_limit
            tvoc_limit
            aiq_limit
            voc_limit
            pressure_high
            pressure_low
            pm1_limit
            pm2_limit
            pm4_limit
            pm10_limit
            light_low
            light_high
            light_white_low
            light_white_high
            uix_low
            uix_high
            relay1
            relay2
            rpm_low
            rpm_high
            tem1_low
            tem1_high
            tem2_low
            tem2_high
            gw_things_low
            gw_things_high
            gw_sensors_low
            gw_sensors_high
            gw_beacons_low
            gw_beacons_high
            voltage_high
            voltage_low
            battery_low
            battery_high
            cap1_rp_low
            cap1_rp_high
            cap2_rp_low
            cap2_rp_high
            __typename
          }
          config_pro {
            interval_sec
            tem_offset
            __typename
          }
          hum
          tem
          dew
          co2
          tvoc
          aiq
          voc
          pressure
          pm1
          pm2
          pm4
          pm10
          pm1_nc
          pm2_nc
          pm4_nc
          pm10_nc
          pm_typical
          resistance
          rpm
          tem1
          tem2
          voltage
          hours
          light
          light_white
          uix
          uix_sec
          relay1
          relay2
          gw_things
          gw_sensors
          gw_beacons
          beacon_uid
          beacon_namespace_id
          beacon_instance_id
          beacon_tx
          beacon_tlm
          beacon_time
          cnd1
          cnd2
          cnd3
          cnd4
          cap1_t
          cap1_r
          cap1_rp
          cap2_t
          cap2_r
          cap2_rp
          flow_t
          uid1
          uid2
          rssi
          wifi_rssi
          lte_rssi
          lpwan_rssi
          mno
          mno_iccid
          state_info
          state_job
          __typename
        }
        __typename
      }
      share {
        user_id
        share_id
        resource
        type
        authorized
        role
        status
        description
        message
        email
        created
        updated
        __typename
      }
      __typename
    }
  }
`;
export const getUserThingBySerial = /* GraphQL */ `
  query GetUserThingBySerial($serialnr: ID!) {
    getUserThingBySerial(serialnr: $serialnr) {
      user_id
      serialnr
      type
      asset_id
      gwid
      monitoring
      notes
      photo
      tags
      status
      created
      updated
      alarm_sensor {
        tem_high {
          active
          timestamp
          state
          __typename
        }
        tem_low {
          active
          timestamp
          state
          __typename
        }
        hum_high {
          active
          timestamp
          state
          __typename
        }
        hum_low {
          active
          timestamp
          state
          __typename
        }
        co2_limit {
          active
          timestamp
          state
          __typename
        }
        tvoc_limit {
          active
          timestamp
          state
          __typename
        }
        aiq_limit {
          active
          timestamp
          state
          __typename
        }
        voc_limit {
          active
          timestamp
          state
          __typename
        }
        pm1_limit {
          active
          timestamp
          state
          __typename
        }
        pm2_limit {
          active
          timestamp
          state
          __typename
        }
        pm4_limit {
          active
          timestamp
          state
          __typename
        }
        pm10_limit {
          active
          timestamp
          state
          __typename
        }
        light_low {
          active
          timestamp
          state
          __typename
        }
        light_high {
          active
          timestamp
          state
          __typename
        }
        light_white_low {
          active
          timestamp
          state
          __typename
        }
        light_white_high {
          active
          timestamp
          state
          __typename
        }
        uix_low {
          active
          timestamp
          state
          __typename
        }
        uix_high {
          active
          timestamp
          state
          __typename
        }
        rpm_low {
          active
          timestamp
          state
          __typename
        }
        rpm_high {
          active
          timestamp
          state
          __typename
        }
        tem1_low {
          active
          timestamp
          state
          __typename
        }
        tem1_high {
          active
          timestamp
          state
          __typename
        }
        gw_things_low {
          active
          timestamp
          state
          __typename
        }
        gw_things_high {
          active
          timestamp
          state
          __typename
        }
        gw_sensors_low {
          active
          timestamp
          state
          __typename
        }
        gw_sensors_high {
          active
          timestamp
          state
          __typename
        }
        gw_beacons_low {
          active
          timestamp
          state
          __typename
        }
        gw_beacons_high {
          active
          timestamp
          state
          __typename
        }
        cap1_rp_low {
          active
          timestamp
          state
          __typename
        }
        cap1_rp_high {
          active
          timestamp
          state
          __typename
        }
        cap2_rp_low {
          active
          timestamp
          state
          __typename
        }
        cap2_rp_high {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_sensor_state
      alarm_connection {
        disconnected {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_connection_state
      alarm_battery {
        battery_low {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_battery_state
      alarm_signal {
        signal_low {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_signal_state
      alarm_hardware {
        sensor {
          active
          timestamp
          state
          __typename
        }
        memory {
          active
          timestamp
          state
          __typename
        }
        storage {
          active
          timestamp
          state
          __typename
        }
        clock {
          active
          timestamp
          state
          __typename
        }
        selftest {
          active
          timestamp
          state
          __typename
        }
        environment {
          active
          timestamp
          state
          __typename
        }
        overheat {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_hardware_state
      alarm_firmware {
        update_failed {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_firmware_state
      alarm_power {
        powerloss {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_power_state
      online
      ts_unix
      ts_iso
      shadow {
        desired {
          config {
            name
            location
            gps
            alarm_mode
            tem_high
            tem_low
            hum_high
            hum_low
            co2_limit
            tvoc_limit
            aiq_limit
            voc_limit
            pressure_high
            pressure_low
            pm1_limit
            pm2_limit
            pm4_limit
            pm10_limit
            light_low
            light_high
            light_white_low
            light_white_high
            uix_low
            uix_high
            relay1
            relay2
            rpm_low
            rpm_high
            tem1_low
            tem1_high
            tem2_low
            tem2_high
            gw_things_low
            gw_things_high
            gw_sensors_low
            gw_sensors_high
            gw_beacons_low
            gw_beacons_high
            voltage_high
            voltage_low
            battery_low
            battery_high
            cap1_rp_low
            cap1_rp_high
            cap2_rp_low
            cap2_rp_high
            __typename
          }
          config_pro {
            interval_sec
            tem_offset
            __typename
          }
          __typename
        }
        reported {
          serialnr
          type
          online
          ts_unix
          ts_iso
          gps
          limits
          model
          boot
          battery
          firmware
          config {
            name
            location
            gps
            alarm_mode
            tem_high
            tem_low
            hum_high
            hum_low
            co2_limit
            tvoc_limit
            aiq_limit
            voc_limit
            pressure_high
            pressure_low
            pm1_limit
            pm2_limit
            pm4_limit
            pm10_limit
            light_low
            light_high
            light_white_low
            light_white_high
            uix_low
            uix_high
            relay1
            relay2
            rpm_low
            rpm_high
            tem1_low
            tem1_high
            tem2_low
            tem2_high
            gw_things_low
            gw_things_high
            gw_sensors_low
            gw_sensors_high
            gw_beacons_low
            gw_beacons_high
            voltage_high
            voltage_low
            battery_low
            battery_high
            cap1_rp_low
            cap1_rp_high
            cap2_rp_low
            cap2_rp_high
            __typename
          }
          config_pro {
            interval_sec
            tem_offset
            __typename
          }
          hum
          tem
          dew
          co2
          tvoc
          aiq
          voc
          pressure
          pm1
          pm2
          pm4
          pm10
          pm1_nc
          pm2_nc
          pm4_nc
          pm10_nc
          pm_typical
          resistance
          rpm
          tem1
          tem2
          voltage
          hours
          light
          light_white
          uix
          uix_sec
          relay1
          relay2
          gw_things
          gw_sensors
          gw_beacons
          beacon_uid
          beacon_namespace_id
          beacon_instance_id
          beacon_tx
          beacon_tlm
          beacon_time
          cnd1
          cnd2
          cnd3
          cnd4
          cap1_t
          cap1_r
          cap1_rp
          cap2_t
          cap2_r
          cap2_rp
          flow_t
          uid1
          uid2
          rssi
          wifi_rssi
          lte_rssi
          lpwan_rssi
          mno
          mno_iccid
          state_info
          state_job
          __typename
        }
        __typename
      }
      share {
        user_id
        share_id
        resource
        type
        authorized
        role
        status
        description
        message
        email
        created
        updated
        __typename
      }
      __typename
    }
  }
`;
export const getUserThings = /* GraphQL */ `
  query GetUserThings($user_id: ID!) {
    getUserThings(user_id: $user_id) {
      user_id
      serialnr
      type
      asset_id
      gwid
      monitoring
      notes
      photo
      tags
      status
      created
      updated
      alarm_sensor {
        tem_high {
          active
          timestamp
          state
          __typename
        }
        tem_low {
          active
          timestamp
          state
          __typename
        }
        hum_high {
          active
          timestamp
          state
          __typename
        }
        hum_low {
          active
          timestamp
          state
          __typename
        }
        co2_limit {
          active
          timestamp
          state
          __typename
        }
        tvoc_limit {
          active
          timestamp
          state
          __typename
        }
        aiq_limit {
          active
          timestamp
          state
          __typename
        }
        voc_limit {
          active
          timestamp
          state
          __typename
        }
        pm1_limit {
          active
          timestamp
          state
          __typename
        }
        pm2_limit {
          active
          timestamp
          state
          __typename
        }
        pm4_limit {
          active
          timestamp
          state
          __typename
        }
        pm10_limit {
          active
          timestamp
          state
          __typename
        }
        light_low {
          active
          timestamp
          state
          __typename
        }
        light_high {
          active
          timestamp
          state
          __typename
        }
        light_white_low {
          active
          timestamp
          state
          __typename
        }
        light_white_high {
          active
          timestamp
          state
          __typename
        }
        uix_low {
          active
          timestamp
          state
          __typename
        }
        uix_high {
          active
          timestamp
          state
          __typename
        }
        rpm_low {
          active
          timestamp
          state
          __typename
        }
        rpm_high {
          active
          timestamp
          state
          __typename
        }
        tem1_low {
          active
          timestamp
          state
          __typename
        }
        tem1_high {
          active
          timestamp
          state
          __typename
        }
        gw_things_low {
          active
          timestamp
          state
          __typename
        }
        gw_things_high {
          active
          timestamp
          state
          __typename
        }
        gw_sensors_low {
          active
          timestamp
          state
          __typename
        }
        gw_sensors_high {
          active
          timestamp
          state
          __typename
        }
        gw_beacons_low {
          active
          timestamp
          state
          __typename
        }
        gw_beacons_high {
          active
          timestamp
          state
          __typename
        }
        cap1_rp_low {
          active
          timestamp
          state
          __typename
        }
        cap1_rp_high {
          active
          timestamp
          state
          __typename
        }
        cap2_rp_low {
          active
          timestamp
          state
          __typename
        }
        cap2_rp_high {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_sensor_state
      alarm_connection {
        disconnected {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_connection_state
      alarm_battery {
        battery_low {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_battery_state
      alarm_signal {
        signal_low {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_signal_state
      alarm_hardware {
        sensor {
          active
          timestamp
          state
          __typename
        }
        memory {
          active
          timestamp
          state
          __typename
        }
        storage {
          active
          timestamp
          state
          __typename
        }
        clock {
          active
          timestamp
          state
          __typename
        }
        selftest {
          active
          timestamp
          state
          __typename
        }
        environment {
          active
          timestamp
          state
          __typename
        }
        overheat {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_hardware_state
      alarm_firmware {
        update_failed {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_firmware_state
      alarm_power {
        powerloss {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_power_state
      online
      ts_unix
      ts_iso
      shadow {
        desired {
          config {
            name
            location
            gps
            alarm_mode
            tem_high
            tem_low
            hum_high
            hum_low
            co2_limit
            tvoc_limit
            aiq_limit
            voc_limit
            pressure_high
            pressure_low
            pm1_limit
            pm2_limit
            pm4_limit
            pm10_limit
            light_low
            light_high
            light_white_low
            light_white_high
            uix_low
            uix_high
            relay1
            relay2
            rpm_low
            rpm_high
            tem1_low
            tem1_high
            tem2_low
            tem2_high
            gw_things_low
            gw_things_high
            gw_sensors_low
            gw_sensors_high
            gw_beacons_low
            gw_beacons_high
            voltage_high
            voltage_low
            battery_low
            battery_high
            cap1_rp_low
            cap1_rp_high
            cap2_rp_low
            cap2_rp_high
            __typename
          }
          config_pro {
            interval_sec
            tem_offset
            __typename
          }
          __typename
        }
        reported {
          serialnr
          type
          online
          ts_unix
          ts_iso
          gps
          limits
          model
          boot
          battery
          firmware
          config {
            name
            location
            gps
            alarm_mode
            tem_high
            tem_low
            hum_high
            hum_low
            co2_limit
            tvoc_limit
            aiq_limit
            voc_limit
            pressure_high
            pressure_low
            pm1_limit
            pm2_limit
            pm4_limit
            pm10_limit
            light_low
            light_high
            light_white_low
            light_white_high
            uix_low
            uix_high
            relay1
            relay2
            rpm_low
            rpm_high
            tem1_low
            tem1_high
            tem2_low
            tem2_high
            gw_things_low
            gw_things_high
            gw_sensors_low
            gw_sensors_high
            gw_beacons_low
            gw_beacons_high
            voltage_high
            voltage_low
            battery_low
            battery_high
            cap1_rp_low
            cap1_rp_high
            cap2_rp_low
            cap2_rp_high
            __typename
          }
          config_pro {
            interval_sec
            tem_offset
            __typename
          }
          hum
          tem
          dew
          co2
          tvoc
          aiq
          voc
          pressure
          pm1
          pm2
          pm4
          pm10
          pm1_nc
          pm2_nc
          pm4_nc
          pm10_nc
          pm_typical
          resistance
          rpm
          tem1
          tem2
          voltage
          hours
          light
          light_white
          uix
          uix_sec
          relay1
          relay2
          gw_things
          gw_sensors
          gw_beacons
          beacon_uid
          beacon_namespace_id
          beacon_instance_id
          beacon_tx
          beacon_tlm
          beacon_time
          cnd1
          cnd2
          cnd3
          cnd4
          cap1_t
          cap1_r
          cap1_rp
          cap2_t
          cap2_r
          cap2_rp
          flow_t
          uid1
          uid2
          rssi
          wifi_rssi
          lte_rssi
          lpwan_rssi
          mno
          mno_iccid
          state_info
          state_job
          __typename
        }
        __typename
      }
      share {
        user_id
        share_id
        resource
        type
        authorized
        role
        status
        description
        message
        email
        created
        updated
        __typename
      }
      __typename
    }
  }
`;
export const getUserThingsByAsset = /* GraphQL */ `
  query GetUserThingsByAsset($user_id: ID!, $asset_id: ID!) {
    getUserThingsByAsset(user_id: $user_id, asset_id: $asset_id) {
      user_id
      serialnr
      type
      asset_id
      gwid
      monitoring
      notes
      photo
      tags
      status
      created
      updated
      alarm_sensor {
        tem_high {
          active
          timestamp
          state
          __typename
        }
        tem_low {
          active
          timestamp
          state
          __typename
        }
        hum_high {
          active
          timestamp
          state
          __typename
        }
        hum_low {
          active
          timestamp
          state
          __typename
        }
        co2_limit {
          active
          timestamp
          state
          __typename
        }
        tvoc_limit {
          active
          timestamp
          state
          __typename
        }
        aiq_limit {
          active
          timestamp
          state
          __typename
        }
        voc_limit {
          active
          timestamp
          state
          __typename
        }
        pm1_limit {
          active
          timestamp
          state
          __typename
        }
        pm2_limit {
          active
          timestamp
          state
          __typename
        }
        pm4_limit {
          active
          timestamp
          state
          __typename
        }
        pm10_limit {
          active
          timestamp
          state
          __typename
        }
        light_low {
          active
          timestamp
          state
          __typename
        }
        light_high {
          active
          timestamp
          state
          __typename
        }
        light_white_low {
          active
          timestamp
          state
          __typename
        }
        light_white_high {
          active
          timestamp
          state
          __typename
        }
        uix_low {
          active
          timestamp
          state
          __typename
        }
        uix_high {
          active
          timestamp
          state
          __typename
        }
        rpm_low {
          active
          timestamp
          state
          __typename
        }
        rpm_high {
          active
          timestamp
          state
          __typename
        }
        tem1_low {
          active
          timestamp
          state
          __typename
        }
        tem1_high {
          active
          timestamp
          state
          __typename
        }
        gw_things_low {
          active
          timestamp
          state
          __typename
        }
        gw_things_high {
          active
          timestamp
          state
          __typename
        }
        gw_sensors_low {
          active
          timestamp
          state
          __typename
        }
        gw_sensors_high {
          active
          timestamp
          state
          __typename
        }
        gw_beacons_low {
          active
          timestamp
          state
          __typename
        }
        gw_beacons_high {
          active
          timestamp
          state
          __typename
        }
        cap1_rp_low {
          active
          timestamp
          state
          __typename
        }
        cap1_rp_high {
          active
          timestamp
          state
          __typename
        }
        cap2_rp_low {
          active
          timestamp
          state
          __typename
        }
        cap2_rp_high {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_sensor_state
      alarm_connection {
        disconnected {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_connection_state
      alarm_battery {
        battery_low {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_battery_state
      alarm_signal {
        signal_low {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_signal_state
      alarm_hardware {
        sensor {
          active
          timestamp
          state
          __typename
        }
        memory {
          active
          timestamp
          state
          __typename
        }
        storage {
          active
          timestamp
          state
          __typename
        }
        clock {
          active
          timestamp
          state
          __typename
        }
        selftest {
          active
          timestamp
          state
          __typename
        }
        environment {
          active
          timestamp
          state
          __typename
        }
        overheat {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_hardware_state
      alarm_firmware {
        update_failed {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_firmware_state
      alarm_power {
        powerloss {
          active
          timestamp
          state
          __typename
        }
        __typename
      }
      alarm_power_state
      online
      ts_unix
      ts_iso
      shadow {
        desired {
          config {
            name
            location
            gps
            alarm_mode
            tem_high
            tem_low
            hum_high
            hum_low
            co2_limit
            tvoc_limit
            aiq_limit
            voc_limit
            pressure_high
            pressure_low
            pm1_limit
            pm2_limit
            pm4_limit
            pm10_limit
            light_low
            light_high
            light_white_low
            light_white_high
            uix_low
            uix_high
            relay1
            relay2
            rpm_low
            rpm_high
            tem1_low
            tem1_high
            tem2_low
            tem2_high
            gw_things_low
            gw_things_high
            gw_sensors_low
            gw_sensors_high
            gw_beacons_low
            gw_beacons_high
            voltage_high
            voltage_low
            battery_low
            battery_high
            cap1_rp_low
            cap1_rp_high
            cap2_rp_low
            cap2_rp_high
            __typename
          }
          config_pro {
            interval_sec
            tem_offset
            __typename
          }
          __typename
        }
        reported {
          serialnr
          type
          online
          ts_unix
          ts_iso
          gps
          limits
          model
          boot
          battery
          firmware
          config {
            name
            location
            gps
            alarm_mode
            tem_high
            tem_low
            hum_high
            hum_low
            co2_limit
            tvoc_limit
            aiq_limit
            voc_limit
            pressure_high
            pressure_low
            pm1_limit
            pm2_limit
            pm4_limit
            pm10_limit
            light_low
            light_high
            light_white_low
            light_white_high
            uix_low
            uix_high
            relay1
            relay2
            rpm_low
            rpm_high
            tem1_low
            tem1_high
            tem2_low
            tem2_high
            gw_things_low
            gw_things_high
            gw_sensors_low
            gw_sensors_high
            gw_beacons_low
            gw_beacons_high
            voltage_high
            voltage_low
            battery_low
            battery_high
            cap1_rp_low
            cap1_rp_high
            cap2_rp_low
            cap2_rp_high
            __typename
          }
          config_pro {
            interval_sec
            tem_offset
            __typename
          }
          hum
          tem
          dew
          co2
          tvoc
          aiq
          voc
          pressure
          pm1
          pm2
          pm4
          pm10
          pm1_nc
          pm2_nc
          pm4_nc
          pm10_nc
          pm_typical
          resistance
          rpm
          tem1
          tem2
          voltage
          hours
          light
          light_white
          uix
          uix_sec
          relay1
          relay2
          gw_things
          gw_sensors
          gw_beacons
          beacon_uid
          beacon_namespace_id
          beacon_instance_id
          beacon_tx
          beacon_tlm
          beacon_time
          cnd1
          cnd2
          cnd3
          cnd4
          cap1_t
          cap1_r
          cap1_rp
          cap2_t
          cap2_r
          cap2_rp
          flow_t
          uid1
          uid2
          rssi
          wifi_rssi
          lte_rssi
          lpwan_rssi
          mno
          mno_iccid
          state_info
          state_job
          __typename
        }
        __typename
      }
      share {
        user_id
        share_id
        resource
        type
        authorized
        role
        status
        description
        message
        email
        created
        updated
        __typename
      }
      __typename
    }
  }
`;
export const getUserThingShadow = /* GraphQL */ `
  query GetUserThingShadow($user_id: ID!, $serialnr: ID!) {
    getUserThingShadow(user_id: $user_id, serialnr: $serialnr) {
      desired {
        config {
          name
          location
          gps
          alarm_mode
          tem_high
          tem_low
          hum_high
          hum_low
          co2_limit
          tvoc_limit
          aiq_limit
          voc_limit
          pressure_high
          pressure_low
          pm1_limit
          pm2_limit
          pm4_limit
          pm10_limit
          light_low
          light_high
          light_white_low
          light_white_high
          uix_low
          uix_high
          relay1
          relay2
          rpm_low
          rpm_high
          tem1_low
          tem1_high
          tem2_low
          tem2_high
          gw_things_low
          gw_things_high
          gw_sensors_low
          gw_sensors_high
          gw_beacons_low
          gw_beacons_high
          voltage_high
          voltage_low
          battery_low
          battery_high
          cap1_rp_low
          cap1_rp_high
          cap2_rp_low
          cap2_rp_high
          __typename
        }
        config_pro {
          interval_sec
          tem_offset
          __typename
        }
        __typename
      }
      reported {
        serialnr
        type
        online
        ts_unix
        ts_iso
        gps
        limits
        model
        boot
        battery
        firmware
        config {
          name
          location
          gps
          alarm_mode
          tem_high
          tem_low
          hum_high
          hum_low
          co2_limit
          tvoc_limit
          aiq_limit
          voc_limit
          pressure_high
          pressure_low
          pm1_limit
          pm2_limit
          pm4_limit
          pm10_limit
          light_low
          light_high
          light_white_low
          light_white_high
          uix_low
          uix_high
          relay1
          relay2
          rpm_low
          rpm_high
          tem1_low
          tem1_high
          tem2_low
          tem2_high
          gw_things_low
          gw_things_high
          gw_sensors_low
          gw_sensors_high
          gw_beacons_low
          gw_beacons_high
          voltage_high
          voltage_low
          battery_low
          battery_high
          cap1_rp_low
          cap1_rp_high
          cap2_rp_low
          cap2_rp_high
          __typename
        }
        config_pro {
          interval_sec
          tem_offset
          __typename
        }
        hum
        tem
        dew
        co2
        tvoc
        aiq
        voc
        pressure
        pm1
        pm2
        pm4
        pm10
        pm1_nc
        pm2_nc
        pm4_nc
        pm10_nc
        pm_typical
        resistance
        rpm
        tem1
        tem2
        voltage
        hours
        light
        light_white
        uix
        uix_sec
        relay1
        relay2
        gw_things
        gw_sensors
        gw_beacons
        beacon_uid
        beacon_namespace_id
        beacon_instance_id
        beacon_tx
        beacon_tlm
        beacon_time
        cnd1
        cnd2
        cnd3
        cnd4
        cap1_t
        cap1_r
        cap1_rp
        cap2_t
        cap2_r
        cap2_rp
        flow_t
        uid1
        uid2
        rssi
        wifi_rssi
        lte_rssi
        lpwan_rssi
        mno
        mno_iccid
        state_info
        state_job
        __typename
      }
      __typename
    }
  }
`;
export const getUserThingTelemetry = /* GraphQL */ `
  query GetUserThingTelemetry(
    $user_id: ID!
    $serialnr: ID!
    $ts_begin: String!
    $ts_end: String!
  ) {
    getUserThingTelemetry(
      user_id: $user_id
      serialnr: $serialnr
      ts_begin: $ts_begin
      ts_end: $ts_end
    ) {
      type
      ts_iso
      ts_unix
      limits
      gps
      hum
      tem
      dew
      co2
      tvoc
      aiq
      voc
      pressure
      pm1
      pm2
      pm4
      pm10
      pm1_nc
      pm2_nc
      pm4_nc
      pm10_nc
      pm_typical
      resistance
      rpm
      tem1
      tem2
      voltage
      hours
      light
      light_white
      uix
      uix_sec
      relay1
      relay2
      gw_things
      gw_sensors
      gw_beacons
      battery
      cnd1
      cnd2
      cnd3
      cnd4
      cap1_t
      cap1_r
      cap1_rp
      cap2_t
      cap2_r
      cap2_rp
      flow_t
      uid1
      uid2
      rssi
      wifi_rssi
      lte_rssi
      lpwan_rssi
      __typename
    }
  }
`;
